import { VFC } from "react";
import { Avatar, Badge, Button } from "antd";
import { useHistory } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";

import { formatDate, formatRelativeTime } from "../i18n";
import { BusinessNotificationType, Notification, NotificationStatus } from "../queries/api/types";
import { HourglassFull, Inbox, Message, OrderAdded, OrderCancelled } from "./icons";
import { useReadNotification } from "../queries/notifications";
import { getRoute, RoutePathName } from "../routes";

interface NotificationCardProps {
    notification: Notification;
    closeNotifications: () => void;
}

const commentTypeIcon = (
    <Avatar size={32} className="text-base !bg-brand-blue-600 !flex items-center justify-center" icon={<Message />} />
);

const actionRequiredIcon = (
    <Avatar
        size={32}
        className="text-base !bg-brand-red-600 !flex items-center justify-center"
        icon={<HourglassFull />}
    />
);

const warningOrangeIcon = (
    <Avatar
        size={32}
        className="text-base !bg-brand-orange-600 !flex items-center justify-center"
        icon={<WarningOutlined />}
    />
);

const warningRedIcon = (
    <Avatar
        size={32}
        className="text-base !bg-brand-red-600 !flex items-center justify-center"
        icon={<WarningOutlined />}
    />
);

const tourOrderAddedIcon = (
    <Avatar
        size={32}
        className="text-base !bg-brand-blue-600 !flex items-center justify-center"
        icon={<OrderAdded />}
    />
);

const orderCanceledIcon = (
    <Avatar
        size={32}
        className="text-base !bg-brand-blue-600 !flex items-center justify-center"
        icon={<OrderCancelled />}
    />
);

const getNotificationIcon = (
    businessNotificationType?: Notification["payload"]["custom"]["businessNotificationType"]
) => {
    switch (businessNotificationType) {
        case BusinessNotificationType.addCommentByOperator:
            return commentTypeIcon;
        case BusinessNotificationType.addCommentByShippingManager:
            return commentTypeIcon;
        case BusinessNotificationType.updateCommentByOperator:
            return commentTypeIcon;
        case BusinessNotificationType.updateCommentByShippingManager:
            return commentTypeIcon;
        case BusinessNotificationType.deleteCommentByOperator:
            return commentTypeIcon;
        case BusinessNotificationType.deleteCommentByShippingManager:
            return commentTypeIcon;
        case BusinessNotificationType.forcedPlatformPermissionRequest:
            return actionRequiredIcon;
        case BusinessNotificationType.forcedPlatformPermissionResponse:
            return <></>;
        case BusinessNotificationType.forcedTrailerPermission:
            return actionRequiredIcon;
        case BusinessNotificationType.declineDelivery:
            return warningRedIcon;
        case BusinessNotificationType.tourOrderAdded:
            return tourOrderAddedIcon;
        case BusinessNotificationType.cancelTourOrder:
            return orderCanceledIcon;
        case BusinessNotificationType.changePlatform:
            return warningOrangeIcon;
        default:
            return <Avatar size={32} className="text-base bg-brand-blue-600" icon={<Inbox />} />;
    }
};

const NotificationCard: VFC<NotificationCardProps> = ({ notification, closeNotifications }) => {
    const history = useHistory();
    const { mutate: readNotification } = useReadNotification();

    const onClickNotification = () => {
        if (notification.status === NotificationStatus.received) {
            readNotification(notification.id);
        }
        closeNotifications();
        if (
            [
                BusinessNotificationType.addCommentByOperator,
                BusinessNotificationType.addCommentByShippingManager,
                BusinessNotificationType.updateCommentByOperator,
                BusinessNotificationType.updateCommentByShippingManager,
                BusinessNotificationType.deleteCommentByOperator,
                BusinessNotificationType.deleteCommentByShippingManager,
                BusinessNotificationType.forcedPlatformPermissionRequest,
                BusinessNotificationType.forcedTrailerPermission,
                BusinessNotificationType.cancelTourOrder,
                BusinessNotificationType.changePlatform,
                BusinessNotificationType.declineDelivery,
                BusinessNotificationType.tourOrderAdded,
            ].includes(notification.payload.custom.businessNotificationType)
        ) {
            history.push(
                getRoute(RoutePathName.tourOrderDetails, { tourOrderId: notification.payload.custom.tourOrderId })
            );
        }
    };

    return (
        <Button className="flex gap-12 items-center" onClick={onClickNotification}>
            <div className="self-center">
                {getNotificationIcon(notification.payload.custom.businessNotificationType)}
            </div>
            <div>
                <div className="mb-4">{notification.payload.text.fr}</div>
                <time
                    dateTime={notification.sendAt}
                    title={formatDate(notification.sendAt, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                    })}
                    className="text-xs text-brand-neutral-light-grey"
                >
                    {formatRelativeTime(notification.sendAt)}
                </time>
            </div>
            <div className="notification-status">
                {notification.status === NotificationStatus.received && <Badge color="#0066CC" dot />}
            </div>
        </Button>
    );
};
export default NotificationCard;
